import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import { Sort } from "../services/utils"

const Article = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  const title = article.hero.title || article.name
  article.pageMetaData.url = article.url
  return (
    <Layout meta={article.pageMetaData || { title: article.title }}>
      <Hero data={article.hero} title={title} options={{ gravity: "center" }} />
      <Features
        features={Sort(article.features, article.featuresSort)}
        startSide="left"
      />
      {article.ctas && article.ctas.map(cta => <FullCTA data={cta} />)}
    </Layout>
  )
}

export default Article

export const query = graphql`
  query HomeQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        featuresSort
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
